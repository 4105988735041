import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Articles",
    component: function () {
      return import("../views/articles/Articles.vue");
    },
  },
  {
    path: "/Articles/Computers",
    component: function () {
      return import("@/views/articles/tech/computers/computers.vue");
    },
  },
  {
    path: "/Articles/Productivity",
    component: function () {
      return import("@/views/articles/tech/productivity/productivity.vue");
    },
  },
  {
    path: "/Articles/Tricking-Trampoline",
    component: function () {
      return import(
        "@/views/articles/sports/tricking-trampoline/tricking-trampoline.vue"
      );
    },
  },

  {
    path: "/Articles/Productivity/typing-faster",
    component: function () {
      return import("@/views/articles/tech/productivity/typing-faster.vue");
    },
  },
  {
    path: "/Articles/Sports/barani-twisting-side",
    component: function () {
      return import(
        "@/views/articles/sports/tricking-trampoline/barani-twisting-side.vue"
      );
    },
  },

  {
    path: "/about",
    name: "About",
    component: function () {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
