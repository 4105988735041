<template>
  <div id="nav">
    <div class="navbar">
      <router-link to="/" class="website-title item">Home Page</router-link>
      <router-link to="/Articles" class="element item">Articles</router-link>
      <router-link to="/Donate" class="element item">Donate</router-link>
    </div>
  </div>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  text-align: justify;
}
.navbar {
  border-bottom: solid 1px white;
  display: flex;
  justify-content: left;
  a {
    color: white;
    text-decoration: none;
  }
  .element {
    margin-right: 20px;
  }
  .item {
    padding: 10px;
    transition: 0.4s;
  }
  .item:hover {
    background-color: #1e1d73;
  }
}
.website-title {
  margin-right: 40px;
  @media (min-width: 1200px) {
    margin-left: 20vw;
  }
}
.author-picture {
  border-radius: 1000px;
}
.underline {
  font-style: underline;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #161111;
  color: white;
  display: flex;
  flex-direction: column;
  font-family: Helvetica;
}
.main-wrapper {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 50px;
}

.center-wrapper {
  display: flex;
  justify-content: center;
}

.no-style {
  color: inherit;
  text-decoration: none;
}
</style>
