<template>
  <div class="home">
    <div class="center-wrapper">
      <div class="main-wrapper">
        <div class="header">
          <h1>Thomas' Blog</h1>
          <div class="right">
            <img
              class="author-picture"
              src="../assets/pp.png"
              alt="author-picture"
            />
          </div>
        </div>
        <p>
          This blog is about everything I feel like sharing with the world would
          be interesting.
        </p>
        <p>
          Typing questions into a search engine often leads to a forum with
          quick (and often nice) answers. However it is sometimes great to find
          guides or more detailed answers. These have been a wonderful help and
          interesting to read through in my journey accross various interests,
          and I want to be part of them.
        </p>
        <p>
          I also find that stumbling accross people's blog and discover about
          their insterests and writings can be inspiring. I might be one of them
          for you !
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>
<style lang="scss">
.header {
  margin-top: 20px;
  display: flex;
  white-space: nowrap;
}

.header img {
  width: 20vw;
  max-width: 200px;
}

.right {
  width: 100%;
  text-align: right;
}
</style>
